.section-top {
  width: 100%;
  padding: 85px 0 40px;
  &.bg {
    background-color: #ebf4fa;
  }
}

.case {
  width: 100%;
  max-width: 1098px;
  padding-right: 21px;
  padding-left: 21px;
  margin-right: auto;
  margin-left: auto;
  &.title-bottom {
    p {
      font-size: 32px;
      line-height: 1.56;
      color: #454f5b;
      max-width: 750px;
      margin: 0 auto;
      padding: 0 0 78px;
    }
  }
}

.column-2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.border {
  border-top: solid 2px #c4cdd5;
}

.main-img {
  position: relative;
  width: 100%;
  max-width: 440px;
  height: 435px;
  order: 1;
  margin-top: 17px;
  img {
    max-width: 100%;
    width: 440px;
    height: 435px;
    max-height: 100%;
    &.circle {
      animation: rotation 15s infinite linear;
    }
    &.hand-red {
      position: absolute;
      bottom: -18px;
      right: -20px;
    }
  }
}

.text-section {
  max-width: 612px;
  padding-top: 119px;
  color: #454f5b;
  h1 {
    font-size: 32px;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 50px;
  }
  h2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
    margin-top: 40px;
  }
  p {
    font-size: 16px;
    line-height: 1.75;
    margin-top: 25px;
  }
}

.section-wrapper {
  width: 100%;
  padding: 64px 0 12px;
  &.bg {
    background-color: #ebf4fa;
  }
}
.title-block {
  font-size: 32px;
  font-weight: normal;
  text-transform: uppercase;
  padding-top: 55px;
  text-align: center;
  color: #454f5b;
  position: relative;
  &.lower {
    text-transform: none;
  }
  &:after {
    content: ' ';
    @extend %line-1px;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.services-list {
  padding: 70px 48px 0;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  column-width: 300px;
  text-align: center;
}

.grid-items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 78px 15px 30px;
  li {
    a {
      display: inline-block;
      height: 50px;
      max-width: 350px;
    }
  }
  &.license {
    padding: 55px 15px;
    li {
      display: flex;
      img {
        width: 45px;
      }
      a {
        color: #333333;
        max-width: 200px;
        margin-left: 20px;
        height: auto;
        align-self: center;
      }
    }
  }
  //&.license {
  //  padding: 55px 15px 10px;
  //  justify-content: space-around;
  //  li {
  //    width: 25%;
  //    margin: 0 0 80px;
  //    text-align: center;
  //    cursor: pointer;
  //    &.itm1 {
  //      a {
  //        @include sprite($license-1);
  //      }
  //    }
  //    &.itm2 {
  //      a {
  //        @include sprite($license-2);
  //      }
  //    }
  //    &.itm3 {
  //      a {
  //        @include sprite($license-3);
  //      }
  //    }
  //    &.itm4 {
  //      a {
  //        @include sprite($license-3);
  //      }
  //    }
  //    &.itm5 {
  //      a {
  //        @include sprite($license-4);
  //      }
  //    }
  //    &.itm6 {
  //      a {
  //        @include sprite($license-5);
  //      }
  //    }
  //    &.itm7 {
  //      a {
  //        @include sprite($license-6);
  //      }
  //    }
  //    &.itm8 {
  //      a {
  //        @include sprite($license-7);
  //      }
  //    }
  //  }
  //}
  &.partners {
    li {
      margin: 0 20px 30px;
      height: 50px;
      max-width: 350px;
      cursor: pointer;
      //line-height: 60px;
      a {
        height: 50px;
        max-width: 350px;
        img {
          height: 100%;
          width: auto;
          max-height: 100%;
          max-width: 100%;
        }

      }
      //&.itm1 {
      //  a {
      //    @include sprite($brend-1);
      //  }
      //}
      //&.itm2 {
      //  a {
      //    @include sprite($brend-2);
      //  }
      //}
      //&.itm3 {
      //  a {
      //    @include sprite($brend-3);
      //  }
      //}
      //&.itm4 {
      //  a {
      //    @include sprite($brend-4);
      //  }
      //}
      //&.itm5 {
      //  a {
      //    @include sprite($brend-5);
      //  }
      //}
      //&.itm6 {
      //  a {
      //    @include sprite($brend-6);
      //  }
      //}
      //&.itm7 {
      //  a {
      //    @include sprite($brend-1);
      //  }
      //}
      //&.itm8 {
      //  a {
      //    @include sprite($brend-2);
      //  }
      //}
      //&.itm9 {
      //  a {
      //    @include sprite($brend-3);
      //  }
      //}
      //&.itm10 {
      //  a {
      //    @include sprite($brend-4);
      //  }
      //}
    }
  }
  &.projects {
    padding: 35px 15px 70px;
    justify-content: center;
    li {
      margin: 0 20px 30px;
      height: 50px;
      max-width: 350px;
      a {
        height: 50px;
        max-width: 350px;
        img {
          height: 100%;
          width: auto;
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

.service {
  display: flex;
  justify-content: center;
  max-width: 725px;
  margin: 0 auto;
  padding-top: 113px;
  .service-icon {
    margin-right: 112px;
    padding-top: 85px;
    .icon {
      @include sprite($ic-lg-1);
    }
    .service-title{
      color: #47495a;
      font-size: 15px;
      text-align: center;
      margin-top: 40px;
      text-transform: uppercase;
      line-height: 1.5;
    }
  }
  .content {
    p {
      color: #6a7c92;
      font-size: 14px;
      line-height: 1.5;
      padding-bottom: 20px;
      &.text-bold {
        font-size: 20px;
        font-weight: bold;
        color: #454f5b;
      }
    }
    ul {
      list-style-type: disc;
      padding-left: 16px;
      li {
        font-size: 14px;
        color: #6a7c92;
        line-height: 1.5;
      }
    }
  }
}


.service-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
  padding: 0 55px;
  .list-item {
    width: 42%;
    display: flex;
    justify-content: start;
    flex-wrap: nowrap;
    margin-bottom: 45px;
    .numbering {
      position: relative;
      margin-right: 18px;
      flex-shrink: 0;
      .numb-img {
        @include sprite($decor-circle);
        animation: rotation 15s infinite linear;
      }
      span {
        animation: none !important;
        position: absolute;
        top: 30px;
        margin-left: -11px;
        left: 50%;
        color: #6a7c92;
        font-size: 37px;
      }
    }
    .item-description {
      p {
        font-size: 14px;
        color: #6a7c92;
        line-height: 1.5;
        font-weight: bold;
        padding-bottom: 25px;
      }
      ul {
        list-style-type: disc;
        padding-left: 16px;
        li {
          font-size: 14px;
          color: #6a7c92;
          line-height: 1.5;
        }
      }
    }
  }
  &.column {
    padding-top: 15px;
    margin-bottom: 0;
    .list-item {
      width: 100%;
      margin-bottom: 30px;
    }

  }
}

.breadcrumbs {
  padding-top: 15px;
  width: 100%;
  .breadcrumbs-list {
    display: flex;
    justify-content: flex-start;
    .breadcrumbs-item {
      position: relative;
      margin-right: 15px;
      .breadcrumbs-link {
        font-size: 12px;
        color: #69939d;
      }
      &:not(:first-child):before {
        content: "/";
        position: absolute;
        top: 0;
        left: -10px;
        color: #69939d;
      }
    }
  }
}

.section-block {
  max-width: 750px;
  text-align: center;
  box-shadow: 0 8px 20px 0 rgba(224, 224, 224, 0.5);
  padding-top: 30px;
  padding-bottom: 50px;
  margin: 0 auto;
  background-color: #ebf4fa;
  border-radius: 3px;
  .title-projects {
    font-size: 16px;
    line-height: 1.75;
    text-align: center;
    color: #454f5b;
    position: relative;
    &:after {
      content: ' ';
      @extend %line-1px;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  img {
    padding-top: 40px;
  }
}
.title-projects{
  font-size: 16px;
  line-height: 1.75;
  text-align: center;
  color: #454f5b;
  padding-top: 68px;
  position: relative;
  &:after {
    content: ' ';
    @extend %line-1px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.portfolio-block {
  max-width: 560px;
  margin: 0 auto;
  .portfolio-title {
    font-size: 28px;
    line-height: 1.29;
    color: #454f5b;
    padding-top: 40px;
    padding-bottom: 15px;
  }
  .year {
    display: inline-block;
    font-size: 17px;
    font-weight: 700;
    color: #6a7c92;
    padding-bottom: 15px;
  }
  .text-bold {
    padding-bottom: 13px;
  }
  .description{
    .text-bold {
      padding-bottom: 13px;
      font-size: 20px;
      font-weight: bold;
      color: #454f5b;
    }
    p {
      font-size: 14px;
      line-height: 1.5;
      color: #6a7c92;
    }
  }
  .customer-description {
    color: #6a7c92;
    font-size: 14px;
    .text-bold {
      padding-bottom: 13px;
      padding-top: 30px;
      font-size: 20px;
      font-weight: bold;
      color: #454f5b;
    }
    p {
      font-size: 14px;
      line-height: 1.5;
      padding: 20px 0;
    }
    ul {
      list-style: disc;
      padding-top: 17px;
      padding-bottom: 33px;
      li {
        padding-bottom: 15px;
      }
    }
  }
}
.wrap-blocks {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  margin-top: 55px;
  min-height: 490px;
  .wrap-form {
    width: 460px;
    max-height: 490px;
    min-height: 310px;
    overflow: hidden;
    flex-shrink: 0;
    border-radius: 3px;
    box-shadow: 0 8px 20px 0 rgba(224, 224, 224, 0.5);
    background-color: #ebf4fa;
    padding: 25px 10px 18px;
  }
}

.form-title {
  width: 360px;
  margin: 0 auto;
  font-size: 22px;
  line-height: 0.95;
  color: #454f5b;
  padding-bottom: 25px;
}
.form-title-contact{
  font-size: 22px;
  font-weight: normal;
  line-height: 0.95;
  color: #454f5b;
  text-align: center;
  padding-bottom: 19px;
}
.contact-form {
  width: 360px;
  margin: 0 auto;
  .form-group {
    margin-bottom: 5px;
  }
  .help-block {
    margin: 0;
    height: 20px;
   }
  .hide-link {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5;
    color: #797878;
    padding-left: 12px;
    margin-bottom: 0;
    opacity: 0;
    transition: opacity .5s ease-in-out;
    &.show-link {
      opacity: 1;
    }
  }
  .input-form,
  textarea {
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 15px 10px;
    margin-bottom: 5px;
    resize: none;
    outline: 0;
    font-size: 14px;
    @include placeholder {
      font-size: 14px;
      line-height: 1.5;
      color: #999999;
    }
    @include autocompleteColor ( transparent, #333333);
  }
  textarea {
    overflow: hidden;
    height: 90px;
  }
}

.map-img {
  max-width: 680px;
  width: 100%;
  iframe {
    width: 100%;
  }
}

.input-form:focus::-webkit-input-placeholder,
.text-form:focus::-webkit-input-placeholder {
  color: transparent;
}
//-----------------------------new style -----------------------------------------//


.text-bold {
  color: #454f5b;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  padding-bottom: 20px;
  &.center {
    text-align: center;
    padding-bottom: 50px;
  }
}

.customer {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  img {
    object-fit: contain;
    flex-grow: 1;
    //padding-right: 30px;
  }
  .description {
    padding-left: 30px;
  }
}

.info-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 50px;
  margin-bottom: 80px;
  span {
    display: inline-block;
    padding: 19px 25px 19px 57px;
    border-radius: 3px;
    box-shadow: 0 8px 20px 0 rgba(224, 224, 224, 0.5);
    background-color: #ffffff;
    font-size: 20px;
    color: #0e0e0e;
    position: relative;
    line-height: 22px;
    display: flex;
    align-items: center;
    &.address {
      &:before {
        content: '';
        position: absolute;
        left: 24px;
        @include sprite($location);
      }
    }
    &.email {
      margin: 0 12.7% 0 10px;
      min-width: 270px;
      &:before {
        content: '';
        position: absolute;
        left: 24px;
        @include sprite($email);
      }
    }
    &.tel {
      min-width: 296px;
      &:before {
        content: '';
        position: absolute;
        left: 24px;
        @include sprite($phone);
      }
    }
  }
}

//---------------------popup-toggle-------------------------
.popup-toggle {
  bottom: 50px;
  right: 170px;
  position: fixed;
  cursor: pointer;
  .img-circle{
    background-color:#95bdcf;
    box-sizing:content-box;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    box-shadow: 0 3px 7px 0 rgba(224, 224, 224, 0.5);
    @include transform-origin(center);
    .img-circle-block{
      box-sizing:content-box;
      position: absolute;
      top: 50%;
      margin-top: -20px;
      left: 50%;
      margin-left: -30px;
      @include transform-origin(center);
      @include sprite($email-popup);
      @include animation(tossing 1.5s infinite );
    }
  }
}

.modal-b {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10000;
  top: 0;
  left: 0;
  visibility: hidden;
  &.is-visible {
    visibility: visible;
    .modal-overlay {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s;
    }
    .modal-transition {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: hsla(0, 0%, 0%, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.3s, opacity 0.3s;
  }
  .modal-wrapper {
    width: 466px;
    height: 510px;
    border-radius: 3px;
    box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    position: fixed;
    bottom: 80px;
    right: 170px;
    z-index: 9999;
    padding-top: 37px;
    .contact-form {
      width: 385px;
    }
    .modal-close {
      position: absolute;
      right: 35px;
      top: 15px;
      width: 14px;
      height: 14px;
      &:hover {
        opacity: 0.3;
      }
      &:before,
      &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 14px;
        width: 2px;
        background-color: #454f5b;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
  }
  .modal-transition {
    transition: all 0.3s 0.12s;
    transform: translateY(-10%);
    opacity: 0;
  }
}



//..........................
.about-pg {
  .main-img {
    max-width: 303px;
    height: 315px;
    margin-right: 52px;
    margin-top: 20px;
    .hand {
      max-width: 100%;
      width: 303px;
      height: 315px;
      max-height: 100%;
    }
  }
  .text-section {
    max-width: 555px;
    padding-top: 50px;
    padding-bottom: 0;
    padding-left: 153px;
    box-sizing: content-box;

    h1 {
      font-weight: bold;
      position: relative;
      margin-bottom: 20px;
      display: inline-block;

      &:after {
        content: ' ';
        @extend %line-2px;
        position: absolute;
        bottom: -19px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}


.block-item {
  display: inline-block;
  overflow: hidden;
  margin: 0 10px 20px;
  text-align: center;
  max-width: 300px;
  width: 100%;
  height: 300px;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 8px 20px 0 rgba(224, 224, 224, 0.5);
  &:hover {
    background-color: #ebf4fa;
  }
  .item-link {
    display: inline-block;
    padding: 40px 23px;
    color: #47495a;
    width: 100%;
    height: 100%;
  }
  .item-img {
    margin: 0 auto;
  }
  .ic-1 { @include sprite($ic-1);}
  .ic-2 { @include sprite($ic-2);}
  .ic-3 { @include sprite($ic-3);}
  .ic-4 { @include sprite($ic-4);}
  .ic-5 { @include sprite($ic-5);}
  .ic-6 { @include sprite($ic-6);}
  .ic-7 { @include sprite($ic-7);}
  .item-info {
    padding-top: 40px;
    .title {
      text-transform: uppercase;
      font-size: 15px;
      line-height: 1.5;
    }
    .text {
      color: #6a7c92;
      font-size: 14px;
      line-height: 1.5;
    }
  }
  &:nth-child(6),
  &:nth-child(1) {
    margin-top: 116px;
  }
}

.about-list {
  padding: 23px 48px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  .block-item {
    padding: 35px 15px;
    margin-bottom: 0;
    .item-info {
      .title {
        transition: 0.6s ease;
      }
      .text {
        font-weight: bold;
        margin-top: 30px;
        text-align: left;
        transition: 0.6s ease;
        opacity: 0;
        transform: translateY(200%);
      }
    }
    &:nth-child(1) {
      margin-top: 0;
    }
    &:nth-child(2) {
      margin-top: 116px;
    }
    &:hover {
      .item-info {
        .title {
          font-weight: bold;
          font-size: 22px;
          line-height: 0.95;
          color: #454f5b;
        }
        .text {
          opacity: 1;
          transform: translateY(0%);
        }
      }
    }
  }
}


.header-block {
  margin-top: 20px;
  h2 {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    color: #454f5b;
    margin-bottom: 15px;
    display: inline-block;
    position: relative;
    &:after {
      content: ' ';
      @extend %line-1px;
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);

    }
  }

}
%line-1px {
  width: 65px;
  height: 1px;
  border: solid 1px #c4cdd5;
}
%line-2px {
  width: 65px;
  height: 2px;
  border: solid 1px #c4cdd5;
}
//keyframes
@-webkit-keyframes rotation {
  from {-webkit-transform: rotate(0deg);}
  to   {-webkit-transform: rotate(359deg);}
}
@include keyframes(tossing) {
  0% {transform: rotate(-8deg);}
  50% {transform: rotate(8deg);}
  100% {transform: rotate(-8deg);}
}