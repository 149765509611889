.button{
  background-color: #6ea1bc;
  box-shadow: 0 8px 20px 0 rgba(110, 161, 188, 0.27);
  border-radius: 2px;
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  text-transform: uppercase;
}

.btn-main {
  @extend  .button;
  display: inline-block;
  width: 205px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.btn-form {
  @extend  .button;
  width: 100%;
  height: 50px;
}