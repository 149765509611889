@include breakpoint(1055) {
  .about-pg {
    .text-section {
      padding-left: 0;
    }
  }
  .header-wrapper {
    .contacts {
      display: block;
       li {
          padding-bottom: 5px;
          padding-right: 0;
       }
    }
  }

  .block-item {
    &:nth-child(6) {
      margin-top: 0;
    }
  }

  .contact-form {
    width: 100%;
    padding: 0 15px;
    .btn-form {
      //width: 360px;
      display: block;
      margin: 0 auto;
    }
  }
  .wrap-blocks {
    flex-wrap: wrap;
    width: 600px;
    margin: 55px auto 0;
    .wrap-form {
      width: 100%;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    .map-img {
      max-width: 100%;
    }
  }

  .info-block {
    max-width: 750px;
    margin: 50px auto 80px;
    span {
      padding: 19px 10px 19px 35px;
      font-size: 16px;
      &.address {
        &:before {
          left: 9px;
        }
      }
      &.email {
        margin-right: 10px;
        min-width: 195px;
        &:before {
          left: 9px;
        }
      }
      &.tel {
        min-width: 216px;
        &:before {
          left: 9px;
        }
      }
    }
  }
}

@include breakpoint(800) {
  .pagingInfo {
    text-align: center;
    display: block;
  }
  .title-projects {
    text-transform: uppercase;
    //font-size: 20px;
    font-weight: normal;
    line-height: 1.3;
    padding-top: 40px;


  }
  .grid-items {
    &.projects {
      padding: 50px 0 0;
      justify-content: space-around;
      li {
        //width: 50%;
        //margin-bottom: 30px;
        //text-align: center;
      }
    }
  }
  .tabbed {
    margin-top: 70px;
    .tab-title {
      float: none;
      max-width: 100%;
      padding-bottom: 15px;
    }
    label {
      width: 100%;
      margin-bottom: 15px;
    }

  }
  .slick-initialized {
    .slick-slide {
      display: block;
    }
  }
  .slick-carousel {
    .slide-content {
      padding: 20px 70px 10px;
    }
  }
  .tabs {

  }
  .breadcrumbs {
    display: none;
  }
  .service-list {
    padding: 0;
  }
  .title-bottom {
    padding: 30px 0;
  }
  .section-top {
    &.about-pg {
      background-color: #ebf4fa;
    }
  }
  .border {
    border-top: none;
  }
  .text-section {
    padding: 28px 6px 0;
    h1 {
      line-height: 1.5;
      margin-bottom: 20px;
    }
  }
  .main-img {
    margin: 0 auto;

  }
  .about-pg {
    .main-img {
      margin: 0 auto;
    }
    .text-section {
      padding: 28px 6px 0;
      max-width: 100%;
    }
  }

  .services-list {
    padding: 46px 0 0;
  }
  .section-wrapper {
    background-color: #f8fafc;
    //padding: 0 0 20px;
  }
  .header {
    position: static;
    border-bottom: 1px solid #c4cdd5;
    height: 52px;
    padding: 11px 0 10px;
    .header-wrapper {
      .contacts {
        display: none;
      }
    }
  }
  .logo-container {
    .logo {
      height: 31px;
    }
  }
  .section-top {
    padding: 30px 0 40px;
    background-color: #f8fafc;
  }
  .column-2 {
    display: block;

  }
  .select-lang {
    margin-right: 35px;
  }
  .navBurger {
    display:block;
  }
  .overlay.open {
    opacity: 0.99;
    visibility: visible;
    transform: scale(1);
  }
  .main-top {
    padding: 30px 0 40px;
  }
  footer {
    padding-top: 25px;
  }
  .col-list {
    width: 50%;
    padding: 0 6px 15px;
    &:nth-child(1) {
      order: 2;
    }
    &:nth-child(2) {
      order: 4;
      width: 100%;
      border-top: 1px solid #c4cdd5;
      padding-bottom: 0;
      text-transform: none;
      h5 {
        display: none;
      }
      .list {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding-top: 10px;
      }
    }
    &:nth-child(3) {
      order: 3;
      width: 100%;
      padding-bottom: 10px;
      .list {
        column-count: 2;
      }
    }
    &:nth-child(4) {
      order: 1;
    }
  }
  .address {
    line-height: 1.8;
    a {
      color: #454f5b;
    }
  }
  .copyright {
    color: #454f5b;
    line-height: 1.8;
    padding-top: 10px;
  }
  .info-block {
    display: block;
    max-width: 600px;
    margin-top: 30px;
    span {
      width: 100%;
      &.email {
        margin: 15px 0;
      }
    }
  }
  .service {
    padding-top: 83px;
    .service-icon {
      margin-right: 25px;
      margin-left: 25px;
    }
  }
  .about-list {
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px 25px 0;
    .block-item {
      margin-bottom: 25px;
      .item-info {
        .text {
          margin-top: 15px;
        }
      }

      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }
  .grid-items {
    &.partners {
      li {
        width: 150px;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    &.license {
      li {
        //width: 180px;
        //margin-left: 10px;
        //margin-right: 10px;
      }
    }
  }

  .title-block {
    padding-top: 0;
  }
  .header-nav {
    .menu {
      display: none;
    }
  }


}

@include breakpoint(740) {
  .info-block {
    margin: 30px auto 25px;
    max-width: 100%;
  }
  .wrap-blocks {
    width: 100%;
  }

  .block-item {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .service-list {
    padding: 0 50px;
    .list-item {
      width: 100%;
    }
  }
}

@include breakpoint(580) {
  .slick-carousel {
    //max-width: 750px;
    //width: 100%;
    height: 200px;
    //margin: 0 auto;
    .slide-content {
      height: 200px;
      //background-color: #ebf4fa;
      //border-radius: 3px;
      //box-shadow: 0 8px 20px 0 rgba(224, 224, 224, 0.5);
      padding: 10px 50px;
      //position: relative;
      //display: flex !important;
      //align-items: center;
      & > a {
        width: 100%;
      }
      .item-el {
        flex-direction: column;
        //justify-content: center;
        //align-items: center;
        .img-slider {
          margin-right: 0;
          //margin-bottom: 23px;
          //margin-right: 20px;
          //width: 50%;
          img {
            margin-bottom: 10px;
            max-height: 80px;
            max-width: 175px;
          }

        }
        p {
          font-size: 14px;
          text-align: center;
          //font-weight: bold;
          //color: #454f5b;
          //padding-bottom: 30px;
        }
      }

      //.pagingInfo {
      //  position: absolute;
      //  bottom: 10px;
      //  right: 19px;
      //}
    }
  }
  .slick-next {
    right: 3px;
  }
  .slick-prev {
    left:3px;
  }

  //-----------------
  .customer {
    display: block;
    padding-bottom: 20px;
    text-align: center;
    .description {
      padding-left: 0;
      padding-top: 20px;
      flex-grow: 1;
    }
  }
  .portfolio-block{
    .portfolio-title {
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      padding-bottom: 30px;
    }
    .text-bold {
      padding-bottom: 28px;
      font-size: 14px;
    }
    .description {
      font-size: 12px;
    }
    .customer-description {
      margin-bottom: 20px;
      .text-bold {
        padding-bottom: 15px;
      }
    .service-list {
      .list-item {
        .item-description {
          p {
            font-weight: normal;
          }
        }
      }
    }
    }
  }
  .section-block {
    .title-projects {
      font-size: 14px;
    }
    img {
      width: 166px;
    }
  }
  .service {
    display: block;
    padding-top: 30px;
    .service-icon {
      padding-top: 0;
      zoom: 0.8;
      img {
        margin: 0 auto 40px;
        display: block;
      }
      .service-title{
        font-size: 20px;
        margin-top: 0;
        padding-bottom: 47px;
        position: relative;
        &:after {
          content: ' ';
          display: block;
          height: 1px;
          width: 65px;
          position: absolute;
          bottom: 27px;
          left: 50%;
          transform: translateX(-50%);
          background-color:  #c4cdd5;
        }
      }
    }
    .content {
      .text-bold {
        font-size: 14px;
      }
      .text {
      font-size: 12px
      }
    }
  }
}

@include breakpoint(500) {
  .tabbed {
    .tab-title {
      font-size: 14px;
      line-height: 1;
    }
    label {
      //width: 76px;
      //height: 23px;
      margin-left: 0;
      margin-right: 0;
      height: auto;
      font-size: 9.8px;
    }
    label:hover, input:checked + label {
      &:after {
       zoom: 0.7;
        margin-top: -3px;
      }
    }

  }
  .tabs {

  }
  .wrap-blocks {
    .wrap-form {
      //padding: 22px 30px 0;
      //width: 100%;
      //margin-bottom: 10px;
    }
  }
  .contact-form {
    padding: 0;
    .form-text {
      font-size: 12px;
      color: #454f5b;
      padding-bottom: 20px;
    }
    .hide-link {
      display: none;
    }
    .input-form,
    textarea {
      border-radius: 1.5px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      //padding: 7px 10px;
      //margin-bottom: 20px;
      //font-size: 7px;
      @include placeholder {
        //font-size: 7px;
      }
    }
    textarea {
      //height: 56px;
    }
    .btn-form {
      box-shadow: 0 4px 10px 0 rgba(110, 161, 188, 0.27);
      border-radius: 1px;
      //font-size: 7px;
      width: 100%;
      //height: 32px;
    }
  }
  .title-bottom {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #454f5b;

  }
  .text-section {
    h1 {
      font-size: 20px;
    }
    &.about-pg {
      h1 {
        display: block;
        text-align: center;
        margin-bottom: 47px;
      }
      p {
        line-height: 1.67;
      }
    }
  }
  .main-img {
    //background: url("../img/png/circle-mob.png") no-repeat;
    width: 100%;
    max-width: 220px;
    height: 220px;
  }

  .about-pg {
    .main-img{
      width: 100%;
      max-width: 176px;
      height: 170px;
    }
    .text-section {
      h1 {
        font-weight: normal;
        text-align: center;
        display: block;
      }
      h2 {
        font-size: 14px;
        margin-top: 25px;
      }
      p {
        font-size: 12px;
      }
      .link-block {
        //.wrap-link {
        //  display: block;
        //  margin-right: 0;
          .link-right {
            padding-top: 26px;
          }
        //}
        //.img-play {
        //  margin-top: 10px;
        //}
      }
    }
  }
  .btn-main {
    width: 207px;
    height: 50px;
  }

  .block-item {
    margin: 5px 10px 20px;
    padding: 25px 15px;
    .item-info {
      padding-top: 25px;
      .title {
        font-size: 14px;
        line-height: 1.43;
      }
      .text {
        font-size: 12px;
        line-height: 1.42;
      }
    }
  }

  .title-block {
    font-size: 20px;
  }
  .block-item {
    width: 240px;
    height: 240px;
  }
  .address {
    padding-top: 10px;
  }
  .popup-toggle {
    display: none;
  }

  .footer-socials {
    li {
      .facebook {
        @include sprite($fb-mob);
      }
      .linkedin {
        @include sprite($link-mob);
      }
    }
  }
  .contact {
    li {
      font-size: 10px;
    }
  }
  .col-list{
    font-size: 10px;
    h5 {
      font-size: 10px;
      padding-bottom: 10px;
      padding-top: 5px;
    }
    &:nth-child(2) {
      font-size: 11px;
    }
  }
  .contact-form {
    .hide-link {
      display: none;
    }
    .btn-form {
      width: 100%;
    }
  }
  .wrap-form {
    height: auto;
  }
  .service-list {
    margin-bottom: 0;
    padding: 0;
    .list-item {
      margin-bottom: 28px;
      .item-description {
        p {
          padding-bottom: 5px;
          font-size: 12px;
        }
        ul {
          li {
            font-size: 10px;
            line-height: 1.8;
          }
        }
      }
      .numbering {
        .numb-img {
          margin: 0 auto;
          zoom: 0.6;
        }
        span {
          font-size: 18px;
          margin-left: -6px;
          top: 21px;
        }
      }
    }
  }
  .info-block {
    span {
      font-size: 12px;
      color: #454f5b;
    }
  }
}

@include breakpoint(380) {
  .wrap-blocks {
    .map-img {
      height: 400px;
      iframe {
        height: 400px;
      }
    }
  }
  .form-title {
    width: 100%;
    text-align: center;
  }
  .contact-form {
    padding: 0;
  }
}
  //
  //.tabbed { width: 400px }
  //.tabbed > label { display: none }
  //.tabs > div {
  //  width: 400px;
  //  border: none;
  //  padding: 0;
  //  opacity: 1;
  //  position: relative;
  //  transform: none;
  //  margin-bottom: 60px;
  //}
  //.tabs > div h2 {
  //  border-bottom: 2px solid #4EC6DE;
  //  padding-bottom: .5em;
  //}
