footer {
  background-color: #f4f6f8;
  padding-top: 50px;
  padding-bottom: 70px;
}
.inner-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.contact {
  li {
    color: #454f5b;
    font-size: rem(18);
    margin-bottom: 10px;
    a {
      color: inherit;
      @extend  .contacts-style;
    }
  }
}
.footer-socials {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-top: 18px;
  li {
    margin-right: 20px;
    .facebook {
      @include sprite($fb);
      display: inline-block;
    }
    .linkedin {
      @include sprite($link);
      display: inline-block;
    }
  }
}
.col-list{
  font-size: 14px;
  h5 {
    color: #919eab;
    font-size: 12px;
    padding-bottom: 18px;
    text-transform: uppercase;
  }
  &:nth-child(2) {
    text-transform: uppercase;
  }
}
.list {
  li {
    line-height: 2;
    a {
      color: #454f5b;
    }
  }
}
.address {
  padding-top: 18px;
  line-height: 2;
  a {
    color: #919eab;
  }
}
.copyright {
  color: #919eab;
  line-height: 2;
}

