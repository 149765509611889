.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  background-color: #ebf4fa;
  padding: 27px 0 13px;
  transition: all 0.4s ease;
  max-height: 85px;
  &.fixed {
    box-shadow: 0px 1px 17px -1px rgba(0, 0, 0, 0.3);
  }
}

.header-wrapper {
  width: 100%;
  height: 100%;
  max-width: 1098px;
  padding-right: 21px;
  padding-left: 21px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s ease;
  .left {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
  }
  .contacts {
    display: flex;
    justify-content: space-between;

    li {
      font-size: rem(14);
      padding-right: 33px;
      transition: 0.6s ease;
      a {
        @extend  .contacts-style;
        color: #454f5b;
      }
    }
  }
}

.header-nav {
  display: flex;
  position: relative;
  .menu {
    display: flex;
    justify-content: space-between;
    position: relative;
    .menu-el {
      padding-right: 27px;
      text-transform: uppercase;
      font-size: rem(12);
      a {
        color: #69939d;
      }
    }
  }
}


.select-lang {
  width: 32px;
  height: 20px;
  padding-right: 17px;
  position: relative;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    top: 6px;
    right: 0;
    display: inline-block;
    @include sprite($arrow);
  }
  &.select-active {
    &:after {
      transform: rotate(-180deg);
    }
    a {
      text-decoration: none;
    }
  }
  .selected {
    line-height: 20px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: rem(12);
    color: #69939d;
    a {
      color: #69939d;
    }
  }
  .option-list {
    display: none;
    width: 100%;
    bottom: 100%;
    .option {
      height: 25px;
      cursor: pointer;
      text-transform: uppercase;
      font-size: rem(12);
      a {
        color: #69939d;
      }
    }
  }
}

.navBurger {
  position: absolute;
  display: none;
  z-index: 6;
  right: 21px;
  top: 25px;
  background: #6b7c92;
  box-sizing: border-box;
  background-clip: content-box;
  width: 19px;
  height: 2px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  &:before,
  &:after {
    content: "";
    position: absolute;
    background: #6b7c92;
    height: 2px;
    width: 100%;
    right: 0;
    will-change: rotate;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  &:before {
    top: -7px;
  }
  &:after {
    bottom: -7px;
    width: 9px;
  }
  &.active {
    background: transparent;
    background-clip: content-box;
  }
  &.active:before {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    top: 0;
    width: 110%;
  }
  &.active:after {
    bottom: 0;
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    width: 110%;
  }
}

.overlay {
  position: fixed;
  z-index: 5;
  background: #ebf4fa;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform-origin: right top;
  transform: scale(0);
  visibility: hidden;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  &.open {
    .overlay-menu {
      li {
        display: block;
        animation: fadeInRight 0.4s cubic-bezier(0.42, 0.83, 0.7, 1.5) forwards;
        transform: rotate(-7deg);
        transform-origin: right bottom;
        @include staggered_animation($items:4,$nth:1,$initial:.4,$step:.1);
      }
    }
  }
}

.overlay-menu {
  position: absolute;
  text-align: right;
  right: 10vw;
  top: 75px;
  .mob-menu {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: block;
    position: relative;
    height: 100%;
    li {
      display: block;
      position: relative;
      opacity: 0;
      width: 100%;
      a {
        display: inline-block;
        color: #454f5b;
        text-decoration: none;
        font-size: 20px;
        padding: 10px 40px 10px 40px;
        margin: 5px 0;
        transition: 0.2s;
        &:hover,
        &:active,
        &:focus {
          transform: translateX(-2px);
          padding-left: 80px;
          outline: 1px solid rgba(255, 255, 255, 0.3);
          background: rgba(255, 255, 255, 0.03);
        }
      }
    }
  }
}

.contact-wrap {
  position: absolute;
  text-align: center;
  bottom: 45px;
  width: 100%;
  border-top: 1px solid #c4cdd5;
  padding-top: 20px;
  .contact {
    text-align: center;
    li {
      font-size: 14px;
    }
  }
  .footer-socials {
    justify-content: center;
  }
}

.locked {
  overflow: hidden;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 50px;
  }
  100% {
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
  }
}
