html,
body {
  font-size: 14px;
  font-family: 'Lucida Grande', sans-serif;

  font-weight: normal;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
  &:focus {
    outline: none !important;
  }
  &:hover {
    text-decoration: none;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

input,
textarea {
  border: none;
  &:focus {
    outline: none;
  }
}

button {
  cursor: pointer;
  border: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
}

.content{
  max-width: 1090px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  &.type-2 {
    max-width: 780px;
  }
  &.type-3 {
    max-width: 1170px;
  }
}
.content-footer {
  @extend  .content;
  max-width: 1170px;
}

.contacts-style {
  &:before {
    content: '';
    margin-right: 8px;
    margin-bottom: -2px;
    display: inline-block;
  }
  &.phone {
    &:before {
      @include sprite($ic-phone);
    }
  }
  &.mail {
    &:before {
      @include sprite($ic-e-mail);
    }
  }
}
