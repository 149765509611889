.slick-carousel {
  max-width: 750px;
  width: 100%;
  height: 170px;
  margin: 0 auto;
  .slide-content {
    height: 170px;
    background-color: #ebf4fa;
    border-radius: 3px;
    box-shadow: 0 8px 20px 0 rgba(224, 224, 224, 0.5);
    padding: 20px 80px 10px 142px;
    position: relative;
    display: flex !important;
    align-items: center;
    .item-el {
      display: flex;
      justify-content: center;
      align-items: center;
      .img-slider {

        //margin-bottom: 23px;
        margin-right: 20px;
        //width: 50%;
        img {
          max-height: 110px;
          max-width: 200px;
        }

      }
      p {
        font-size: 16px;
        font-weight: bold;
        color: #454f5b;
        //padding-bottom: 30px;
      }
    }

    .pagingInfo {
      position: absolute;
      bottom: 10px;
      right: 19px;
    }
  }
}

.slick-prev,
.slick-next {
  position: absolute;
  cursor: pointer;
  top: 50%;
  margin-top: -21px;
}
.slick-next {
  right: 19px;
  @include sprite($next-button);
}
.slick-prev {
  left:19px;
  z-index: 1;
  @include sprite($prev-button);
}
.pagingInfo {
  font-size: 12px;
  color: #454f5b;
}


//tabs
.tabbed {
  max-width: 750px;
  margin: 50px auto 0;

  input {
    display: none;
  }
  .tab-title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.57;
    color: #454f5b;
    margin-bottom: 15px;
  }
  label {
    display: inline-block;
    width: 47%;
    height: 33px;

    text-align: center;
    //padding: 6px 18px;
    border-radius: 20px;
    background-color: #ebf4fa;
    margin-bottom: 15px;
    margin-right: 10px;
    margin-left: 10px;
    transition: background-color .3s;
    a {
      text-decoration: none;
      font-size: 14px;
      font-weight: bold;
      line-height: 33px;
      color: #454f5b;
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    &.active {
      background-color: #95bdcf;
      position: relative;
      a {
        color: #ffffff;
      }
    }
  }
  label:hover, input:checked + label{
    background: #95bdcf;
    position: relative;
    a {
      color: #ffffff;
    }
  }
}
//.tab-title {
//  float: left;
//  font-size: 14px;
//  font-weight: bold;
//  line-height: 1.57;
//  color: #454f5b;
//
//  max-width: 113px;
//  margin-right: 25px;
//
//}
//label {
//  display: block;
//  width: 109px;
//
//  height: 33px;
//  float: left;
//
//  padding: 6px 18px;
//  border-radius: 20px;
//  background-color: #ebf4fa;
//  margin-bottom: 25px;
//  margin-right: 15px;
//
//  cursor: pointer;
//  font-size: 14px;
//  font-weight: bold;
//  line-height: 1.57;
//  color: #454f5b;
//  transition: background-color .3s;
//
//}
//label:hover, input:checked + label {
//
//    background: #95bdcf;
//    color: #ffffff;
//    position: relative;
//    //&:after {
//    // content:'';
//    // position: absolute;
//    // right: 18px;
//    // top: 50%;
//    // margin-top: -4px;
//    //@include sprite($close-small);
//    //}
//
//}


.tabs {
  clear: both;
  height: 170px;
  position: relative;
}

.tabs > div {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform: rotateY(-50deg);
  transform-origin: top center;
  transition: opacity .5s, transform 1s;
  z-index: 0;
}

#tab-nav-1:checked ~ .tabs > div:nth-of-type(1),
#tab-nav-2:checked ~ .tabs > div:nth-of-type(2),
#tab-nav-3:checked ~ .tabs > div:nth-of-type(3),
#tab-nav-4:checked ~ .tabs > div:nth-of-type(4),
#tab-nav-5:checked ~ .tabs > div:nth-of-type(5),
#tab-nav-6:checked ~ .tabs > div:nth-of-type(6),
#tab-nav-7:checked ~ .tabs > div:nth-of-type(7){
  transform: rotateX(0);
  opacity: 1;
  z-index: 1;
  width: 100%;
}


