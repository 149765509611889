// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$about-ic-1-name: 'about-ic-1';
$about-ic-1-x: 582px;
$about-ic-1-y: 130px;
$about-ic-1-offset-x: -582px;
$about-ic-1-offset-y: -130px;
$about-ic-1-width: 62px;
$about-ic-1-height: 62px;
$about-ic-1-total-width: 685px;
$about-ic-1-total-height: 545px;
$about-ic-1-image: 'img-sprite.png';
$about-ic-1: (582px, 130px, -582px, -130px, 62px, 62px, 685px, 545px, 'img-sprite.png', 'about-ic-1', );
$about-ic-2-name: 'about-ic-2';
$about-ic-2-x: 389px;
$about-ic-2-y: 222px;
$about-ic-2-offset-x: -389px;
$about-ic-2-offset-y: -222px;
$about-ic-2-width: 91px;
$about-ic-2-height: 106px;
$about-ic-2-total-width: 685px;
$about-ic-2-total-height: 545px;
$about-ic-2-image: 'img-sprite.png';
$about-ic-2: (389px, 222px, -389px, -222px, 91px, 106px, 685px, 545px, 'img-sprite.png', 'about-ic-2', );
$about-ic-3-name: 'about-ic-3';
$about-ic-3-x: 128px;
$about-ic-3-y: 274px;
$about-ic-3-offset-x: -128px;
$about-ic-3-offset-y: -274px;
$about-ic-3-width: 134px;
$about-ic-3-height: 90px;
$about-ic-3-total-width: 685px;
$about-ic-3-total-height: 545px;
$about-ic-3-image: 'img-sprite.png';
$about-ic-3: (128px, 274px, -128px, -274px, 134px, 90px, 685px, 545px, 'img-sprite.png', 'about-ic-3', );
$arrow-name: 'arrow';
$arrow-x: 645px;
$arrow-y: 185px;
$arrow-offset-x: -645px;
$arrow-offset-y: -185px;
$arrow-width: 8px;
$arrow-height: 5px;
$arrow-total-width: 685px;
$arrow-total-height: 545px;
$arrow-image: 'img-sprite.png';
$arrow: (645px, 185px, -645px, -185px, 8px, 5px, 685px, 545px, 'img-sprite.png', 'arrow', );
$brend-1-name: 'brend-1';
$brend-1-x: 206px;
$brend-1-y: 383px;
$brend-1-offset-x: -206px;
$brend-1-offset-y: -383px;
$brend-1-width: 142px;
$brend-1-height: 45px;
$brend-1-total-width: 685px;
$brend-1-total-height: 545px;
$brend-1-image: 'img-sprite.png';
$brend-1: (206px, 383px, -206px, -383px, 142px, 45px, 685px, 545px, 'img-sprite.png', 'brend-1', );
$brend-2-name: 'brend-2';
$brend-2-x: 124px;
$brend-2-y: 108px;
$brend-2-offset-x: -124px;
$brend-2-offset-y: -108px;
$brend-2-width: 120px;
$brend-2-height: 45px;
$brend-2-total-width: 685px;
$brend-2-total-height: 545px;
$brend-2-image: 'img-sprite.png';
$brend-2: (124px, 108px, -124px, -108px, 120px, 45px, 685px, 545px, 'img-sprite.png', 'brend-2', );
$brend-3-name: 'brend-3';
$brend-3-x: 582px;
$brend-3-y: 41px;
$brend-3-offset-x: -582px;
$brend-3-offset-y: -41px;
$brend-3-width: 86px;
$brend-3-height: 47px;
$brend-3-total-width: 685px;
$brend-3-total-height: 545px;
$brend-3-image: 'img-sprite.png';
$brend-3: (582px, 41px, -582px, -41px, 86px, 47px, 685px, 545px, 'img-sprite.png', 'brend-3', );
$brend-4-name: 'brend-4';
$brend-4-x: 349px;
$brend-4-y: 383px;
$brend-4-offset-x: -349px;
$brend-4-offset-y: -383px;
$brend-4-width: 116px;
$brend-4-height: 45px;
$brend-4-total-width: 685px;
$brend-4-total-height: 545px;
$brend-4-image: 'img-sprite.png';
$brend-4: (349px, 383px, -349px, -383px, 116px, 45px, 685px, 545px, 'img-sprite.png', 'brend-4', );
$brend-5-name: 'brend-5';
$brend-5-x: 258px;
$brend-5-y: 109px;
$brend-5-offset-x: -258px;
$brend-5-offset-y: -109px;
$brend-5-width: 127px;
$brend-5-height: 53px;
$brend-5-total-width: 685px;
$brend-5-total-height: 545px;
$brend-5-image: 'img-sprite.png';
$brend-5: (258px, 109px, -258px, -109px, 127px, 53px, 685px, 545px, 'img-sprite.png', 'brend-5', );
$brend-6-name: 'brend-6';
$brend-6-x: 206px;
$brend-6-y: 429px;
$brend-6-offset-x: -206px;
$brend-6-offset-y: -429px;
$brend-6-width: 132px;
$brend-6-height: 28px;
$brend-6-total-width: 685px;
$brend-6-total-height: 545px;
$brend-6-image: 'img-sprite.png';
$brend-6: (206px, 429px, -206px, -429px, 132px, 28px, 685px, 545px, 'img-sprite.png', 'brend-6', );
$brend-7-name: 'brend-7';
$brend-7-x: 582px;
$brend-7-y: 0px;
$brend-7-offset-x: -582px;
$brend-7-offset-y: 0px;
$brend-7-width: 103px;
$brend-7-height: 40px;
$brend-7-total-width: 685px;
$brend-7-total-height: 545px;
$brend-7-image: 'img-sprite.png';
$brend-7: (582px, 0px, -582px, 0px, 103px, 40px, 685px, 545px, 'img-sprite.png', 'brend-7', );
$brend-8-name: 'brend-8';
$brend-8-x: 194px;
$brend-8-y: 478px;
$brend-8-offset-x: -194px;
$brend-8-offset-y: -478px;
$brend-8-width: 110px;
$brend-8-height: 38px;
$brend-8-total-width: 685px;
$brend-8-total-height: 545px;
$brend-8-image: 'img-sprite.png';
$brend-8: (194px, 478px, -194px, -478px, 110px, 38px, 685px, 545px, 'img-sprite.png', 'brend-8', );
$circle-arrow-name: 'circle-arrow';
$circle-arrow-x: 0px;
$circle-arrow-y: 383px;
$circle-arrow-offset-x: 0px;
$circle-arrow-offset-y: -383px;
$circle-arrow-width: 94px;
$circle-arrow-height: 94px;
$circle-arrow-total-width: 685px;
$circle-arrow-total-height: 545px;
$circle-arrow-image: 'img-sprite.png';
$circle-arrow: (0px, 383px, 0px, -383px, 94px, 94px, 685px, 545px, 'img-sprite.png', 'circle-arrow', );
$close-small-name: 'close-small';
$close-small-x: 669px;
$close-small-y: 75px;
$close-small-offset-x: -669px;
$close-small-offset-y: -75px;
$close-small-width: 9px;
$close-small-height: 9px;
$close-small-total-width: 685px;
$close-small-total-height: 545px;
$close-small-image: 'img-sprite.png';
$close-small: (669px, 75px, -669px, -75px, 9px, 9px, 685px, 545px, 'img-sprite.png', 'close-small', );
$decor-circle-name: 'decor-circle';
$decor-circle-x: 389px;
$decor-circle-y: 112px;
$decor-circle-offset-x: -389px;
$decor-circle-offset-y: -112px;
$decor-circle-width: 109px;
$decor-circle-height: 109px;
$decor-circle-total-width: 685px;
$decor-circle-total-height: 545px;
$decor-circle-image: 'img-sprite.png';
$decor-circle: (389px, 112px, -389px, -112px, 109px, 109px, 685px, 545px, 'img-sprite.png', 'decor-circle', );
$email-popup-name: 'email-popup';
$email-popup-x: 582px;
$email-popup-y: 193px;
$email-popup-offset-x: -582px;
$email-popup-offset-y: -193px;
$email-popup-width: 61px;
$email-popup-height: 40px;
$email-popup-total-width: 685px;
$email-popup-total-height: 545px;
$email-popup-image: 'img-sprite.png';
$email-popup: (582px, 193px, -582px, -193px, 61px, 40px, 685px, 545px, 'img-sprite.png', 'email-popup', );
$email-name: 'email';
$email-x: 603px;
$email-y: 277px;
$email-offset-x: -603px;
$email-offset-y: -277px;
$email-width: 22px;
$email-height: 17px;
$email-total-width: 685px;
$email-total-height: 545px;
$email-image: 'img-sprite.png';
$email: (603px, 277px, -603px, -277px, 22px, 17px, 685px, 545px, 'img-sprite.png', 'email', );
$facebook-name: 'facebook';
$facebook-x: 669px;
$facebook-y: 41px;
$facebook-offset-x: -669px;
$facebook-offset-y: -41px;
$facebook-width: 16px;
$facebook-height: 16px;
$facebook-total-width: 685px;
$facebook-total-height: 545px;
$facebook-image: 'img-sprite.png';
$facebook: (669px, 41px, -669px, -41px, 16px, 16px, 685px, 545px, 'img-sprite.png', 'facebook', );
$fb-mob-name: 'fb-mob';
$fb-mob-x: 645px;
$fb-mob-y: 164px;
$fb-mob-offset-x: -645px;
$fb-mob-offset-y: -164px;
$fb-mob-width: 20px;
$fb-mob-height: 20px;
$fb-mob-total-width: 685px;
$fb-mob-total-height: 545px;
$fb-mob-image: 'img-sprite.png';
$fb-mob: (645px, 164px, -645px, -164px, 20px, 20px, 685px, 545px, 'img-sprite.png', 'fb-mob', );
$fb-name: 'fb';
$fb-x: 645px;
$fb-y: 130px;
$fb-offset-x: -645px;
$fb-offset-y: -130px;
$fb-width: 33px;
$fb-height: 33px;
$fb-total-width: 685px;
$fb-total-height: 545px;
$fb-image: 'img-sprite.png';
$fb: (645px, 130px, -645px, -130px, 33px, 33px, 685px, 545px, 'img-sprite.png', 'fb', );
$ic-1-name: 'ic-1';
$ic-1-x: 501px;
$ic-1-y: 0px;
$ic-1-offset-x: -501px;
$ic-1-offset-y: 0px;
$ic-1-width: 80px;
$ic-1-height: 107px;
$ic-1-total-width: 685px;
$ic-1-total-height: 545px;
$ic-1-image: 'img-sprite.png';
$ic-1: (501px, 0px, -501px, 0px, 80px, 107px, 685px, 545px, 'img-sprite.png', 'ic-1', );
$ic-2-name: 'ic-2';
$ic-2-x: 258px;
$ic-2-y: 0px;
$ic-2-offset-x: -258px;
$ic-2-offset-y: 0px;
$ic-2-width: 130px;
$ic-2-height: 108px;
$ic-2-total-width: 685px;
$ic-2-total-height: 545px;
$ic-2-image: 'img-sprite.png';
$ic-2: (258px, 0px, -258px, 0px, 130px, 108px, 685px, 545px, 'img-sprite.png', 'ic-2', );
$ic-3-name: 'ic-3';
$ic-3-x: 124px;
$ic-3-y: 0px;
$ic-3-offset-x: -124px;
$ic-3-offset-y: 0px;
$ic-3-width: 133px;
$ic-3-height: 107px;
$ic-3-total-width: 685px;
$ic-3-total-height: 545px;
$ic-3-image: 'img-sprite.png';
$ic-3: (124px, 0px, -124px, 0px, 133px, 107px, 685px, 545px, 'img-sprite.png', 'ic-3', );
$ic-4-name: 'ic-4';
$ic-4-x: 130px;
$ic-4-y: 166px;
$ic-4-offset-x: -130px;
$ic-4-offset-y: -166px;
$ic-4-width: 122px;
$ic-4-height: 106px;
$ic-4-total-width: 685px;
$ic-4-total-height: 545px;
$ic-4-image: 'img-sprite.png';
$ic-4: (130px, 166px, -130px, -166px, 122px, 106px, 685px, 545px, 'img-sprite.png', 'ic-4', );
$ic-5-name: 'ic-5';
$ic-5-x: 0px;
$ic-5-y: 274px;
$ic-5-offset-x: 0px;
$ic-5-offset-y: -274px;
$ic-5-width: 127px;
$ic-5-height: 108px;
$ic-5-total-width: 685px;
$ic-5-total-height: 545px;
$ic-5-image: 'img-sprite.png';
$ic-5: (0px, 274px, 0px, -274px, 127px, 108px, 685px, 545px, 'img-sprite.png', 'ic-5', );
$ic-6-name: 'ic-6';
$ic-6-x: 0px;
$ic-6-y: 166px;
$ic-6-offset-x: 0px;
$ic-6-offset-y: -166px;
$ic-6-width: 129px;
$ic-6-height: 107px;
$ic-6-total-width: 685px;
$ic-6-total-height: 545px;
$ic-6-image: 'img-sprite.png';
$ic-6: (0px, 166px, 0px, -166px, 129px, 107px, 685px, 545px, 'img-sprite.png', 'ic-6', );
$ic-7-name: 'ic-7';
$ic-7-x: 253px;
$ic-7-y: 166px;
$ic-7-offset-x: -253px;
$ic-7-offset-y: -166px;
$ic-7-width: 134px;
$ic-7-height: 90px;
$ic-7-total-width: 685px;
$ic-7-total-height: 545px;
$ic-7-image: 'img-sprite.png';
$ic-7: (253px, 166px, -253px, -166px, 134px, 90px, 685px, 545px, 'img-sprite.png', 'ic-7', );
$ic-e-mail-name: 'ic-e-mail';
$ic-e-mail-x: 668px;
$ic-e-mail-y: 258px;
$ic-e-mail-offset-x: -668px;
$ic-e-mail-offset-y: -258px;
$ic-e-mail-width: 17px;
$ic-e-mail-height: 14px;
$ic-e-mail-total-width: 685px;
$ic-e-mail-total-height: 545px;
$ic-e-mail-image: 'img-sprite.png';
$ic-e-mail: (668px, 258px, -668px, -258px, 17px, 14px, 685px, 545px, 'img-sprite.png', 'ic-e-mail', );
$ic-lg-1-name: 'ic-lg-1';
$ic-lg-1-x: 0px;
$ic-lg-1-y: 0px;
$ic-lg-1-offset-x: 0px;
$ic-lg-1-offset-y: 0px;
$ic-lg-1-width: 123px;
$ic-lg-1-height: 165px;
$ic-lg-1-total-width: 685px;
$ic-lg-1-total-height: 545px;
$ic-lg-1-image: 'img-sprite.png';
$ic-lg-1: (0px, 0px, 0px, 0px, 123px, 165px, 685px, 545px, 'img-sprite.png', 'ic-lg-1', );
$ic-phone-name: 'ic-phone';
$ic-phone-x: 626px;
$ic-phone-y: 277px;
$ic-phone-offset-x: -626px;
$ic-phone-offset-y: -277px;
$ic-phone-width: 14px;
$ic-phone-height: 14px;
$ic-phone-total-width: 685px;
$ic-phone-total-height: 545px;
$ic-phone-image: 'img-sprite.png';
$ic-phone: (626px, 277px, -626px, -277px, 14px, 14px, 685px, 545px, 'img-sprite.png', 'ic-phone', );
$license-1-name: 'license-1';
$license-1-x: 263px;
$license-1-y: 274px;
$license-1-offset-x: -263px;
$license-1-offset-y: -274px;
$license-1-width: 112px;
$license-1-height: 84px;
$license-1-total-width: 685px;
$license-1-total-height: 545px;
$license-1-image: 'img-sprite.png';
$license-1: (263px, 274px, -263px, -274px, 112px, 84px, 685px, 545px, 'img-sprite.png', 'license-1', );
$license-2-name: 'license-2';
$license-2-x: 582px;
$license-2-y: 89px;
$license-2-offset-x: -582px;
$license-2-offset-y: -89px;
$license-2-width: 98px;
$license-2-height: 40px;
$license-2-total-width: 685px;
$license-2-total-height: 545px;
$license-2-image: 'img-sprite.png';
$license-2: (582px, 89px, -582px, -89px, 98px, 40px, 685px, 545px, 'img-sprite.png', 'license-2', );
$license-3-name: 'license-3';
$license-3-x: 95px;
$license-3-y: 383px;
$license-3-offset-x: -95px;
$license-3-offset-y: -383px;
$license-3-width: 110px;
$license-3-height: 75px;
$license-3-total-width: 685px;
$license-3-total-height: 545px;
$license-3-image: 'img-sprite.png';
$license-3: (95px, 383px, -95px, -383px, 110px, 75px, 685px, 545px, 'img-sprite.png', 'license-3', );
$license-4-name: 'license-4';
$license-4-x: 0px;
$license-4-y: 478px;
$license-4-offset-x: 0px;
$license-4-offset-y: -478px;
$license-4-width: 82px;
$license-4-height: 67px;
$license-4-total-width: 685px;
$license-4-total-height: 545px;
$license-4-image: 'img-sprite.png';
$license-4: (0px, 478px, 0px, -478px, 82px, 67px, 685px, 545px, 'img-sprite.png', 'license-4', );
$license-5-name: 'license-5';
$license-5-x: 83px;
$license-5-y: 478px;
$license-5-offset-x: -83px;
$license-5-offset-y: -478px;
$license-5-width: 110px;
$license-5-height: 44px;
$license-5-total-width: 685px;
$license-5-total-height: 545px;
$license-5-image: 'img-sprite.png';
$license-5: (83px, 478px, -83px, -478px, 110px, 44px, 685px, 545px, 'img-sprite.png', 'license-5', );
$license-6-name: 'license-6';
$license-6-x: 389px;
$license-6-y: 329px;
$license-6-offset-x: -389px;
$license-6-offset-y: -329px;
$license-6-width: 110px;
$license-6-height: 47px;
$license-6-total-width: 685px;
$license-6-total-height: 545px;
$license-6-image: 'img-sprite.png';
$license-6: (389px, 329px, -389px, -329px, 110px, 47px, 685px, 545px, 'img-sprite.png', 'license-6', );
$license-7-name: 'license-7';
$license-7-x: 389px;
$license-7-y: 0px;
$license-7-offset-x: -389px;
$license-7-offset-y: 0px;
$license-7-width: 111px;
$license-7-height: 111px;
$license-7-total-width: 685px;
$license-7-total-height: 545px;
$license-7-image: 'img-sprite.png';
$license-7: (389px, 0px, -389px, 0px, 111px, 111px, 685px, 545px, 'img-sprite.png', 'license-7', );
$link-mob-name: 'link-mob';
$link-mob-x: 582px;
$link-mob-y: 277px;
$link-mob-offset-x: -582px;
$link-mob-offset-y: -277px;
$link-mob-width: 20px;
$link-mob-height: 20px;
$link-mob-total-width: 685px;
$link-mob-total-height: 545px;
$link-mob-image: 'img-sprite.png';
$link-mob: (582px, 277px, -582px, -277px, 20px, 20px, 685px, 545px, 'img-sprite.png', 'link-mob', );
$link-name: 'link';
$link-x: 644px;
$link-y: 193px;
$link-offset-x: -644px;
$link-offset-y: -193px;
$link-width: 33px;
$link-height: 33px;
$link-total-width: 685px;
$link-total-height: 545px;
$link-image: 'img-sprite.png';
$link: (644px, 193px, -644px, -193px, 33px, 33px, 685px, 545px, 'img-sprite.png', 'link', );
$linkedin-name: 'linkedin';
$linkedin-x: 669px;
$linkedin-y: 58px;
$linkedin-offset-x: -669px;
$linkedin-offset-y: -58px;
$linkedin-width: 16px;
$linkedin-height: 16px;
$linkedin-total-width: 685px;
$linkedin-total-height: 545px;
$linkedin-image: 'img-sprite.png';
$linkedin: (669px, 58px, -669px, -58px, 16px, 16px, 685px, 545px, 'img-sprite.png', 'linkedin', );
$location-name: 'location';
$location-x: 668px;
$location-y: 234px;
$location-offset-x: -668px;
$location-offset-y: -234px;
$location-width: 17px;
$location-height: 23px;
$location-total-width: 685px;
$location-total-height: 545px;
$location-image: 'img-sprite.png';
$location: (668px, 234px, -668px, -234px, 17px, 23px, 685px, 545px, 'img-sprite.png', 'location', );
$next-button-name: 'next-button';
$next-button-x: 582px;
$next-button-y: 234px;
$next-button-offset-x: -582px;
$next-button-offset-y: -234px;
$next-button-width: 42px;
$next-button-height: 42px;
$next-button-total-width: 685px;
$next-button-total-height: 545px;
$next-button-image: 'img-sprite.png';
$next-button: (582px, 234px, -582px, -234px, 42px, 42px, 685px, 545px, 'img-sprite.png', 'next-button', );
$next-name: 'next';
$next-x: 582px;
$next-y: 298px;
$next-offset-x: -582px;
$next-offset-y: -298px;
$next-width: 11px;
$next-height: 18px;
$next-total-width: 685px;
$next-total-height: 545px;
$next-image: 'img-sprite.png';
$next: (582px, 298px, -582px, -298px, 11px, 18px, 685px, 545px, 'img-sprite.png', 'next', );
$phone-name: 'phone';
$phone-x: 666px;
$phone-y: 164px;
$phone-offset-x: -666px;
$phone-offset-y: -164px;
$phone-width: 19px;
$phone-height: 19px;
$phone-total-width: 685px;
$phone-total-height: 545px;
$phone-image: 'img-sprite.png';
$phone: (666px, 164px, -666px, -164px, 19px, 19px, 685px, 545px, 'img-sprite.png', 'phone', );
$prev-button-name: 'prev-button';
$prev-button-x: 625px;
$prev-button-y: 234px;
$prev-button-offset-x: -625px;
$prev-button-offset-y: -234px;
$prev-button-width: 42px;
$prev-button-height: 42px;
$prev-button-total-width: 685px;
$prev-button-total-height: 545px;
$prev-button-image: 'img-sprite.png';
$prev-button: (625px, 234px, -625px, -234px, 42px, 42px, 685px, 545px, 'img-sprite.png', 'prev-button', );
$spritesheet-width: 685px;
$spritesheet-height: 545px;
$spritesheet-image: 'img-sprite.png';
$spritesheet-sprites: ($about-ic-1, $about-ic-2, $about-ic-3, $arrow, $brend-1, $brend-2, $brend-3, $brend-4, $brend-5, $brend-6, $brend-7, $brend-8, $circle-arrow, $close-small, $decor-circle, $email-popup, $email, $facebook, $fb-mob, $fb, $ic-1, $ic-2, $ic-3, $ic-4, $ic-5, $ic-6, $ic-7, $ic-e-mail, $ic-lg-1, $ic-phone, $license-1, $license-2, $license-3, $license-4, $license-5, $license-6, $license-7, $link-mob, $link, $linkedin, $location, $next-button, $next, $phone, $prev-button, );
$spritesheet: (685px, 545px, 'img-sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url('/img/png/sprite/#{$sprite-image}');
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
